import React from "react";
import Layout from "../components/layout";
import "../components/css/layout_main.css";
import { Container } from "react-bootstrap";
import "../components/css/about.css";
import Hero from "../components/hero";

const AboutUsPage = () => {
  return (
    <Layout
      pageTitle="Oriental - Address/Contact"
      pageHeading="Address/Contact"
      pageHeadText=""
    >
      <Hero heroTitle="Contact" heroText="" />
      <Container className="contact">
        <h2>Telephone</h2>
        <p>
          020 7254 2145
          <br />
          020 7249 1186
        </p>
        <h2>Address</h2>
        <p>444, kingsland Road, Dalston, London, E8 4AE</p>
        <Container className="iframe_container">
          <iframe
            className="iframe_map"
            title="Address Map"
            src="https://www.openstreetmap.org/export/embed.html?bbox=-0.08222579956054689%2C51.54042655071872%2C-0.06983399391174318%2C51.54457025249696&amp;layer=mapnik&amp;marker=51.54249844877291%2C-0.07602989673614502"
          ></iframe>
        </Container>
        <div className="link_div">
          <a href="https://www.openstreetmap.org/?mlat=51.54251&amp;mlon=-0.07599#map=18/51.54251/-0.07599">
            View larger map
          </a>
        </div>
      </Container>
    </Layout>
  );
};

export default AboutUsPage;
